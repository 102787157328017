import * as Sentry from "@sentry/react";
import { getSiteCountry } from "../shared/utilities";

interface IBrowserToIgnore {
  reg: RegExp;
  regVersionIndex: number;
  minimumVersion: number; // lower version should be ignored
  name: string;
}

/**
 *
 * Use the user agent string to determine if we should initialize
 * sentry for this browser.
 *
 * List of browser to ignore can be deduce from browser list (see package.json)
 *
 * regexp come from `ua-parser-js` package.
 */
const shouldInitSentry = (userAgent: string): boolean => {
  const browsers: Array<IBrowserToIgnore> = [
    {
      name: "mobile safari",
      reg: /version\/([\w.]+)\s.*mobile\/\w+\s(safari)/i,
      minimumVersion: 14,
      regVersionIndex: 1,
    },
    {
      name: "edge",
      reg: /(edge|edgios|edga|edg)\/((\d+)?[\w.]+)/i,
      minimumVersion: 106,
      regVersionIndex: 3,
    },
    {
      name: "safari",
      reg: /version\/([\w.]+)\s.*(mobile\s?safari|safari)/i,
      minimumVersion: 14,
      regVersionIndex: 1,
    },
    {
      name: "chrome",
      reg: /(chrome)\/v?([\w.]+)/i,
      minimumVersion: 105,
      regVersionIndex: 2,
    },
  ];
  return (
    browsers.filter((browser) => {
      const res = userAgent.match(browser.reg);
      if (
        res &&
        res[browser.regVersionIndex] &&
        parseFloat(res[browser.regVersionIndex]) < browser.minimumVersion
      ) {
        return true;
      }
      return false;
    }).length === 0
  );
};

if (shouldInitSentry(navigator.userAgent)) {
  let environment = process.env.ENVIRONMENT;
  const siteCountry = getSiteCountry();
  if (siteCountry !== "GB") {
    environment += `-${siteCountry.toLowerCase()}`;
  }

  Sentry.init({
    dsn: "https://39f2b43b2d714a45be8c284ef04b5e7b@sentry.io/1811032",
    environment,
    release: process.env.COMMIT_SHA,
  });
}

export default shouldInitSentry;
