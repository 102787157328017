import { paths } from "../../../../website-openapi";

export const isNotNull = <T>(arg: T | null): arg is T => arg !== null;

// Returns a Promise that resolves after "ms" Milliseconds
export const timer = (ms: number): Promise<void> =>
  new Promise((res) => {
    setTimeout(res, ms);
  });

type ResponseCode = 200 | 201 | 204;
type OperationType = "get" | "post" | "put" | "delete" | "patch";

type WithResponse<O extends OperationType, T extends ResponseCode> = Record<
  O,
  {
    responses: Record<T, { content: { "application/json": unknown } }>;
  }
>;

export type GetResponse<R extends ResponseCode, T extends keyof paths> =
  paths[T] extends WithResponse<"get", R>
    ? paths[T]["get"]["responses"][R]["content"]["application/json"]
    : never;

export type PostResponse<R extends ResponseCode, T extends keyof paths> =
  paths[T] extends WithResponse<"post", R>
    ? paths[T]["post"]["responses"][R]["content"]["application/json"]
    : never;

export type PutResponse<R extends ResponseCode, T extends keyof paths> =
  paths[T] extends WithResponse<"put", R>
    ? paths[T]["put"]["responses"][R]["content"]["application/json"]
    : never;

export type PatchResponse<R extends ResponseCode, T extends keyof paths> =
  paths[T] extends WithResponse<"patch", R>
    ? paths[T]["patch"]["responses"][R]["content"]["application/json"]
    : never;

export type DeleteResponse<R extends ResponseCode, T extends keyof paths> =
  paths[T] extends WithResponse<"delete", R>
    ? paths[T]["delete"]["responses"][R]["content"]["application/json"]
    : never;

// typescript utils

export class ValueError extends Error {
  constructor(
    public value: never,
    public msg: string = "Unexpected value",
  ) {
    super(`${msg}: ${value}`);
  }
}
